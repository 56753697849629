.ol-zoom-button-container {
  position: absolute;
  bottom: 0;
}

.ol-full-screen-button {
  position: absolute;
  bottom: 0;
  right: 0;
}

.tooltip {
  position: relative;
  padding: 5px 10px;
  border-radius: 5px;
  background: rgba(0, 0, 0, .6);
  color: white;
  opacity: 1;
  white-space: nowrap;
  font-size: small;
  box-sizing: border-box;
}

.tooltip::after {
  content: " ";
  position: absolute;
  bottom: 100%;
  left: 45%;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent rgba(0, 0, 0, .6) transparent;
}